/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps, Link } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Hero } from "../../components";
// import { Hero, Statement } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import socialHead2 from "../../images/insighthand.jpg";
// import { GA_insightheader } from "../../components/GA_insightheader";
// import { Researchblock } from "../../components/DownloadBlock";
// import { GA_insightarticles } from "../../components/GA_insightarticles";
import { Helmet } from "react-helmet";
// import rightIcon from "../../images/Right Button.svg";
// import { Link } from "gatsby";

// const component: React.FC<PageProps<Pick<Query, "graphCmsSectorPage">>> = ({
//   path,
//   data: { graphCmsSectorPage: sectorPage },
// }) => {
//   console.log(sectorPage);
//   if (!sectorPage) return null;
//   return (
//     <Layout
//       pagePath={path}
//       menu={buildMenu(sectorPage.menu)}
//       footerMenu={buildMenu(sectorPage.footerMenu)}
//       keywords={sectorPage.keywords}
//       title={sectorPage.pageTitle || sectorPage.sector}
//       description={sectorPage.description}
//     >
//       <Hero
//         title="Partners in Performance Energy Transition Insights"
//         video={getVideo(sectorPage.heroVideo)}
//         image={getImage(sectorPage.heroImage)}
//         short={true}
//         treatment={sectorPage.heroTreatment}
//         showSearch={true}
//       />
//       <Statement
//         title=""
//         headline="A collection of analysis, research and stories about our capabilities from our Energy Transition Experts."
//         id={""}
//         className="custom-class"
//       />
//       <ResearchComponent name="" />
//     </Layout>
//   );
// };

// export default component;

// export const sectorPageQuery = graphql`
//   query sectorPageQueryAndSectorPageQuery($id: String) {
//     graphCmsSectorPage(id: { eq: $id }) {
//       sectorType
//       pageTitle
//       keywords
//       description
//       menu {
//         ...MenuFragment
//       }
//       businessChallengesIntroduction
//       businessChallenges {
//         headline
//         id
//         content {
//           cleaned
//         }
//       }
//       heroImage {
//         ...ImageFragment
//       }
//       statement {
//         cleaned
//       }
//       heroVideo {
//         ...VideoFragment
//       }
//       heroTreatment
//       id
//       keyStatistic
//       leadership {
//         ...ProfileFragment
//       }
//       leadershipTitle
//       quote
//       quoteFrom {
//         ...ProfileFragment
//       }
//       infographicIntroduction {
//         cleaned
//       }
//       infographicDetails {
//         cleaned
//       }
//       genericInfographicText {
//         cleaned
//       }
//       sector
//       slug
//       statisticDetails
//       storiesTitle
//       storiesImage {
//         ...ImageFragment
//       }
//       successes {
//         __typename
//         ... on GraphCMS_CaseStudy {
//           ...CaseStudyReferenceFragment
//         }
//         ... on GraphCMS_Story {
//           ...StoryReferenceFragment
//         }
//         ... on GraphCMS_Insight {
//           ...InsightReferenceFragment
//         }
//       }
//       form {
//         ...FormFragment
//       }
//     }
//   }
// `;

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  // fetch("https://api.ipify.org?format=json")
  //   .then((response) => {
  //     return response.json();
  //   }, "jsonp")
  //   .then((res) => {
  //     fetch("https://ipapi.co/" + res.ip + "/json")
  //       .then((response) => {
  //         return response.json();
  //       }, "jsonp")
  //       .then((res) => {
  //         console.log(res.country);
  //       })
  //       .catch((err) => console.log(err));
  //     console.log(res.ip);
  //   })
  //   .catch((err) => console.log(err));

  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="Insights | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="Thankyou"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <section className="py-section  bg-white-1 pb-20px">
        <center>
          <div className="">
            <Helmet>
              <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
              <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
              {/* <link
                rel="stylesheet"
                href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
              ></link> */}
            </Helmet>
            {/* <button
              type="button"
              class="btn btn-info btn-lg"
              data-toggle="modal"
              data-target="#myModal"
            >
              Open Modal
            </button> */}
            <h3>
              Thank you. Our team will reach out soon to see how we can help
              you.​
            </h3>
          </div>
          <br></br>
          <Link to="/en/insights">
            <button className="col-start-4 bg-purple text-white-1 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex self-center ga-peopleEverything-CTA">
              Insights
            </button>
          </Link>
          {/* <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            Launch demo modal
          </button> */}
        </center>
        {/* <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 className="modal-title">Modal Header</h4>
              </div>
              <div className="modal-body">
                <p>Some text in the modal.</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </section>

      <>
        <section className="py-section page-grid bg-white-1 pb-20px">
          {/* <a
            href="/en/our-social-values"
            className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
          >
            <img
              src={leftIcon}
              alt="Left"
              className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
            />
            Our social values
          </a> */}
        </section>
      </>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
